import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Seo from "../components/SEO";
import ServiceNav from "../components/ServiceNav";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const Header = styled.header`
  text-align: center;
  align-content: center;
`;

const Heading = styled.h1`
  font-size: 2rem;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: var(--grey);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  max-width: 80%;
`;

const Divider = styled.div`
  background-color: var(--grey);
  height: 1px;
  width: 100%;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.75rem;
  > h2 {
    font-size: 1.65rem;
  }
`;

export default function Coaching({ data }) {
  const services = data.services.nodes;
  const items = data.coaching.nodes;
  const page = data.page.nodes;

  return (
    <>
      <Seo title="Coaching" />
      <Layout>
        <Container>
          <Header>
            {page.map((page) => {
              return (
                <div key={page.id}>
                  <Heading>{page.title}</Heading>
                  <Subtitle>{page.subtitle}</Subtitle>
                </div>
              );
            })}
          </Header>
          <Divider />
          <ItemsContainer>
            <h2>Current Classes</h2>
            {items.map(({ id, name, description, meetingType, price }) => {
              return Item(id, name, description, meetingType, price);
            })}
          </ItemsContainer>
          <Divider />
          <ServiceNav services={services} />
        </Container>
      </Layout>
    </>
  );
}

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 90% auto;
  grid-template-rows: 20px auto 20px;
  grid-template-areas: ". . ." ". coachingContainer ." ". . .";
  border: var(--grey) 1px solid;
  border-radius: 0.725rem;
  box-shadow: 1px 1px 1rem var(--grey);
  width: 100%;
`;

const CoachingContainer = styled.div`
  grid-area: coachingContainer;
`;

const StyledLink = styled(Link)`
  color: var(--blue);
  &:hover {
    text-decoration: none;
  }
`;

const ClassLabel = styled.h2`
  font-weight: 700;
  margin: 0;
`;

const Description = styled.p`
  color: var(--grey);
`;

function Item(id, name, description, meetingType, price) {
  const formatPrice = (price) => {
    if (!price) return "Contact for pricing";
    const value = price / 100;
    return `$${value}`;
  };

  const joinFormats = (meetingType) => {
    return meetingType.map((m) => m.name).join(", ");
  };
  return (
    <ItemContainer key={id}>
      <CoachingContainer>
        <ClassLabel>{name}</ClassLabel>
        <Description>{description}</Description>
        <p>
          <i>{joinFormats(meetingType)}</i> - {formatPrice(price)}
        </p>
        <StyledLink to="/contact">Contact us</StyledLink>
      </CoachingContainer>
    </ItemContainer>
  );
}

export const query = graphql`
  query {
    page: allSanityPageHeader(filter: { page: { eq: "Coaching" } }, limit: 1) {
      nodes {
        id
        title
        subtitle
      }
    }
    services: allSanityServiceType(
      filter: {
        display: { eq: true }
        id: { ne: "-d91d2fcc-98a0-58c1-9cf0-ccc773451334" }
      }
    ) {
      nodes {
        name
        id
        slug {
          current
        }
      }
    }
    coaching: allSanityService(
      filter: {
        serviceType: { id: { eq: "-d91d2fcc-98a0-58c1-9cf0-ccc773451334" } }
      }
    ) {
      nodes {
        id
        name
        description
        meetingType {
          name
        }
        price
      }
    }
  }
`;
